import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedNotificationListComponent } from './shared-notification-list/shared-notification-list.component';
import { TranslateModule } from "@ngx-translate/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SharedModule } from "../../shared.module";



@NgModule({
  declarations: [
    SharedNotificationListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InfiniteScrollModule,
    SharedModule,
  ],
  exports: [
    SharedNotificationListComponent,
  ]
})
export class SharedNotificationModule { }
