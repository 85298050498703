import { createReducer, on } from "@ngrx/store";
import * as UnreadNotificationAction from '../actions/unread-notification.action';

export const unreadNotificationFeatureKey = 'UNREAD_NOTIFICATION_STATE_KEY';

export interface UnreadNotificationState {
  unreadNotification: number | null
}

export const initialState: UnreadNotificationState = {
  unreadNotification: null
};

export const unreadNotificationReducer = createReducer(
  initialState,
  on(UnreadNotificationAction.setUnreadNotification, (state, {unreadNotification}) => ({...state, unreadNotification}))
);
