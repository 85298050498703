<div
  class="row me-0" infiniteScroll
  (scrolled)="onScroll()" [fromRoot]="true"
  [infiniteScrollContainer]="scrollContainer"
>

  <div
    *ngFor="let n of notifications;let i = index"
    [class.unread]="!n.isRead"
    (click)="goToNotificationDetail(n, i)"
    class="col-12 notification-list-item d-flex my-2 ps-4 pe-0 align-items-start clickable">

    <div class="notification-icon">

      <img *ngIf="n.notification?.extras?.img" [src]="n.notification.extras?.img">

      <div class="icon-container" *ngIf="(!n.notification?.extras?.img) && n?.iconKey" >
        <gray-icon [icon]="n.iconKey"></gray-icon>
      </div>

    </div>

    <div class="notification-text ms-2 me-2 w-100">
      <p
        [class.color-gray-950]="!n.isRead"
        [class.color-gray-300]="n.isRead"
        class="fw-400 fs-normal n-content">
        <strong>{{n.notification.notificationTemplate.headings.tr}}<br></strong>
        {{n.notification.notificationTemplate.contents.tr | variableReplacer: n.notification.extras}}
      </p>
      <p class="fw-400 color-gray-300 ms-2 fs-xsmall">
        {{ (n.notification?.sendAt ? n.notification.sendAt : n.notification.createdAt)|relativeTime}}
      </p>
    </div>

  </div>

</div>

<div class="col-md-12 text-center p-3" *ngIf="(!notEmpty) && (!isLoading) && (notifications.length == 0)">
  <p class="color-gray-800">{{'errors.no_record'|translate}}</p>
</div>

<div class="col-md-12 text-center p-3" *ngIf="isLoading">
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
